<template>
  <main>
    <Particles
        id="tsparticles"
        :options="particlesSetting"
    />
    <section class="content">
      <div class="grid grid-cols-1 lg:grid-cols-2 gap-4 h-full max-w-screen-xl m-auto">
        <section class="flex items-center justify-center px-4">
          <div class="flex flex-col flex-start text-center m-auto">
            <h1 class="text-2xl md:text-3xl font-medium text-red-700">Luca Giardi</h1>
            <h2 class="text-3xl md:text-4xl font-bold py-2">Freelance full stack web and iOS Developer,<br>UI Designer</h2>
            <a href="https://www.linkedin.com/in/luca-giardi-91a01288/" target="_blank" class="">@luca.giardi</a>
          </div>
        </section>
        <HomeMenu/>
      </div>
    </section>
  </main>
</template>

<script>

import HomeMenu from "@/modules/home/components/HomeMenu";
import {ref} from 'vue';

export default {
  name: "home",
  components: {HomeMenu},
  setup() {
    const particlesSetting = ref({
      "autoPlay": true,
      "background": {
        "color": {
          "value": "#010C17"
        },
        "image": "",
        "position": "50% 50%",
        "repeat": "no-repeat",
        "size": "20%",
        "opacity": 1
      },
      "backgroundMask": {
        "composite": "destination-out",
        "cover": {
          "color": {
            "value": "#fff"
          },
          "opacity": 1
        },
        "enable": false
      },
      "fullScreen": {
        "enable": true,
        "zIndex": 1
      },
      "detectRetina": true,
      "fpsLimit": 60,
      "infection": {
        "cure": false,
        "delay": 0,
        "enable": false,
        "infections": 0,
        "stages": []
      },
      "interactivity": {
        "detectsOn": "canvas",
        "events": {
          "onClick": {
            "enable": true,
            "mode": "repulse"
          },
          "onDiv": {
            "selectors": [],
            "enable": false,
            "mode": [],
            "type": "circle"
          },
          "onHover": {
            "enable": true,
            "mode": "bubble",
            "parallax": {
              "enable": false,
              "force": 2,
              "smooth": 10
            }
          },
          "resize": true
        },
        "modes": {
          "attract": {
            "distance": 200,
            "duration": 0.4,
            "speed": 1
          },
          "bounce": {
            "distance": 200
          },
          "bubble": {
            "distance": 250,
            "duration": 2,
            "opacity": 0,
            "size": 0
          },
          "connect": {
            "distance": 80,
            "links": {
              "opacity": 0.5
            },
            "radius": 60
          },
          "grab": {
            "distance": 400,
            "links": {
              "blink": false,
              "consent": false,
              "opacity": 1
            }
          },
          "light": {
            "area": {
              "gradient": {
                "start": {
                  "value": "#ffffff"
                },
                "stop": {
                  "value": "#000000"
                }
              },
              "radius": 1000
            },
            "shadow": {
              "color": {
                "value": "#000000"
              },
              "length": 2000
            }
          },
          "push": {
            "quantity": 4
          },
          "remove": {
            "quantity": 2
          },
          "repulse": {
            "distance": 400,
            "duration": 0.4,
            "speed": 1
          },
          "slow": {
            "factor": 3,
            "radius": 200
          },
          "trail": {
            "delay": 1,
            "quantity": 1
          }
        }
      },
      "manualParticles": [],
      "motion": {
        "disable": false,
        "reduce": {
          "factor": 4,
          "value": false
        }
      },
      "particles": {
        "bounce": {
          "horizontal": {
            "random": {
              "enable": false,
              "minimumValue": 0.1
            },
            "value": 1
          },
          "vertical": {
            "random": {
              "enable": false,
              "minimumValue": 0.1
            },
            "value": 1
          }
        },
        "collisions": {
          "bounce": {
            "horizontal": {
              "random": {
                "enable": false,
                "minimumValue": 0.1
              },
              "value": 1
            },
            "vertical": {
              "random": {
                "enable": false,
                "minimumValue": 0.1
              },
              "value": 1
            }
          },
          "enable": false,
          "mode": "bounce"
        },
        "color": {
          "value": "#ffffff",
          "animation": {
            "enable": false,
            "speed": 1,
            "sync": true
          }
        },
        "life": {
          "count": 0,
          "delay": {
            "random": {
              "enable": false,
              "minimumValue": 0
            },
            "value": 0,
            "sync": false
          },
          "duration": {
            "random": {
              "enable": false,
              "minimumValue": 0.0001
            },
            "value": 0,
            "sync": false
          }
        },
        "links": {
          "blink": false,
          "color": {
            "value": "#ffffff"
          },
          "consent": false,
          "distance": 150,
          "enable": false,
          "frequency": 1,
          "opacity": 0.4,
          "shadow": {
            "blur": 5,
            "color": {
              "value": "#00ff00"
            },
            "enable": false
          },
          "triangles": {
            "enable": false,
            "frequency": 1
          },
          "width": 1,
          "warp": false
        },
        "move": {
          "angle": {
            "offset": 45,
            "value": 90
          },
          "attract": {
            "enable": false,
            "rotate": {
              "x": 600,
              "y": 600
            }
          },
          "direction": "none",
          "distance": 0,
          "enable": true,
          "gravity": {
            "acceleration": 9.81,
            "enable": false,
            "maxSpeed": 50
          },
          "noise": {
            "delay": {
              "random": {
                "enable": false,
                "minimumValue": 0
              },
              "value": 0
            },
            "enable": false
          },
          "outModes": {
            "default": "out",
            "bottom": "out",
            "left": "out",
            "right": "out",
            "top": "out"
          },
          "random": true,
          "size": false,
          "speed": 1,
          "straight": false,
          "trail": {
            "enable": false,
            "length": 10,
            "fillColor": {
              "value": "#000000"
            }
          },
          "vibrate": false,
          "warp": false
        },
        "number": {
          "density": {
            "enable": true,
            "area": 800,
            "factor": 1000
          },
          "limit": 0,
          "value": 160
        },
        "opacity": {
          "random": {
            "enable": true,
            "minimumValue": 0.1
          },
          "value": 1,
          "animation": {
            "destroy": "none",
            "enable": true,
            "minimumValue": 0,
            "speed": 1,
            "startValue": "random",
            "sync": false
          }
        },
        "reduceDuplicates": false,
        "rotate": {
          "random": {
            "enable": false,
            "minimumValue": 0
          },
          "value": 0,
          "animation": {
            "enable": false,
            "speed": 0,
            "sync": false
          },
          "direction": "clockwise",
          "path": false
        },
        "shadow": {
          "blur": 0,
          "color": {
            "value": "#000000"
          },
          "enable": false,
          "offset": {
            "x": 0,
            "y": 0
          }
        },
        "shape": {
          "options": {},
          "type": "circle"
        },
        "size": {
          "random": {
            "enable": true,
            "minimumValue": 1
          },
          "value": 3,
          "animation": {
            "destroy": "none",
            "enable": false,
            "minimumValue": 0.3,
            "speed": 4,
            "startValue": "random",
            "sync": false
          }
        },
        "stroke": {
          "width": 0
        },
        "twinkle": {
          "lines": {
            "enable": false,
            "frequency": 0.05,
            "opacity": 1
          },
          "particles": {
            "enable": false,
            "frequency": 0.05,
            "opacity": 1
          }
        }
      },
      "pauseOnBlur": true,
      "pauseOnOutsideViewport": false,
      "themes": []
    });


    return {
      particlesSetting
    }
  }
}
</script>

<style lang="scss" scoped>
#tsparticles {
  @apply fixed bottom-0 w-screen h-screen;
}

.content {
  @apply relative z-10 h-screen flex  w-full;
}
</style>
<template>
  <section class="home-menu">
    <nav>
      <router-link to="about">About</router-link>
      <router-link to="portfolio" class="portfolio">Portfolio</router-link>
      <router-link to="contact" class="contact">Contact</router-link>
    </nav>
  </section>
</template>

<script>
export default {
  name: "HomeMenu",
}
</script>

<style lang="scss" scoped>

.home-menu {
  @apply flex justify-center items-baseline;

  @screen lg {
    @apply items-center
  }
  nav {
    @apply relative flex flex-col items-start text-6xl;
    z-index: 1000;

    a {
      @apply capitalize font-bold flex flex-row items-center justify-center flex-wrap relative cursor-pointer no-underline;
      color: #f4f4f4;
      line-height: 1.25;
      letter-spacing: -0.025em;
      text-indent: -0.025em;

      &:hover, &:focus {
        @apply no-underline;
      }

      &:before {
        content: '';
        width: 60%;
        height: 25px;
        background: linear-gradient(45deg, #f19872, #e86c9a);
        position: absolute;
        left: 0;
        bottom: 0;
        transform-origin: 0 0;
        transform: scale3d(0, 1, 1);
        z-index: -1;
      }

      &:hover::before {
        transition: transform 0.8s cubic-bezier(0.2, 1, 0.3, 1);
        transform: scale3d(1.2, 1, 1);
      }

      &.portfolio::before {
        background: linear-gradient(45deg, #0947db, #07d89d);
      }

      &.contact::before {
        background: linear-gradient(45deg, #3a3d98, #6f22b9);
      }
    }
  }
}
</style>